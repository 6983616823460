import * as React from "react";

export const footerNavItems = {
  links: [
    {name: "Terms & Conditions", href: "/terms-and-conditions"},
    {name: "Privacy Policy", href: "/privacy-policy"},],
  social: [
    {
      name: "Facebook",
      href: "#",
      icon: (props: any) => <i className="fa-brands fa-facebook text-2xl" />,
    },
    {
      name: "Instagram",
      href: "#",
      icon: (props: any) => <i className="fa-brands fa-instagram text-2xl" />,
    },
    {
      name: "YouTube",
      href: "#",
      icon: (props: any) => <i className="fa-brands fa-youtube text-2xl" />,
    },
  ],
};
