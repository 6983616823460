import * as React from "react";
import { LegalList } from "./LegalList";
import { LegalDescription } from "./LegalDescription";

type RefundPolicyPlanProps = {
  refundWindowItems: string[];
  nonRefundableItems: string[];
  refundDenialItems: string[];
};

export const RefundPolicyPlan = (props: RefundPolicyPlanProps) => {
  return (
    <div className="pb-4 pl-2">
      <LegalDescription>
        A full refund of the subscription only offered within the first 48 hours
        of the subscription activation. After this period, no refunds will be
        granted. The conditions are as follows:
      </LegalDescription>
      <div className="pl-4">
        <h4 className="font-semibold mt-2">48-Hour Refund Window:</h4>
        <LegalList items={props.refundWindowItems} />
        <h4 className="font-semibold mt-2">Non-Refundable Fees:</h4>
        <LegalList items={props.nonRefundableItems} />
        <h4 className="font-semibold mt-2">Refund Denial After 48 Hours:</h4>
        <LegalList items={props.refundDenialItems} />
      </div>
      <LegalDescription>
        This policy ensures clarity and aligns with the flat-fee nature of the
        subscription model, establishing a strict, non-negotiable refund
        process.
      </LegalDescription>
    </div>
  );
};
