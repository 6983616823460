import * as React from "react";
import { LegalList } from "./LegalList";

type SubscriptionPlanProps = {
  title: string;
  price: string;
  features: string[];
  suitableFor: string;
};

export const SubscriptionPlan = (props: SubscriptionPlanProps) => {
  return (
    <div className="pb-4 pl-2">
      <h3 className="text-lg font-semibold pb-2">{props.title}</h3>
      <div className="pl-4">
        <p className="font-bold">Price: {props.price} per month</p>
        <h4 className="font-semibold mt-2">Features:</h4>
        <LegalList items={props.features} />
        <p>
          <span className="font-semibold">Suitable For:</span>{" "}
          {props.suitableFor}
        </p>
      </div>
    </div>
  );
};
