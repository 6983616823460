import * as React from "react";
import { forwardRef } from "react";

type SectionWrapperProps = {
  paddingY?: "small" | "medium" | "large";
  paddingX?: "small" | "medium" | "large";
  children: React.ReactNode;
  className?: string;
  id?: string;
};

export const SectionWrapper = forwardRef<HTMLDivElement, SectionWrapperProps>(
  (
    { paddingY = "medium", paddingX = "medium", children, className, id },
    ref
  ) => {
    function sectionPadding(paddingY: string) {
      switch (paddingY) {
        case "small":
          return "py-12 sm:py-16";
        case "medium":
          return "py-24 sm:py-32";
        case "large":
          return "py-32 sm:py-40";
        default:
          return "py-24 sm:py-32";
      }
    }

    function sectionXPadding(paddingX: string) {
      switch (paddingX) {
        case "small":
          return "max-w-4xl px-1 md:px-4 lg:px-6";
        case "medium":
          return "max-w-7xl px-2 md:px-6 lg:px-8";
        case "large":
          return "max-w-10xl px-4 md:px-8 lg:px-10";
        default:
          return "max-w-7xl px-2 md:px-6 lg:px-8";
      }
    }
    return (
      <section
        className={`${sectionPadding(paddingY)}${
          className ? ` ${className}` : ""
        }`}
        ref={ref}
        id={id}
        role="region"
      >
        <div className={`mx-auto ${sectionXPadding(paddingX)}`}>{children}</div>
      </section>
    );
  }
);

SectionWrapper.displayName = "SectionWrapper";
