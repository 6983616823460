import * as React from "react";
// @ts-ignore
import heroBg from "~src/features/Marketing/assets/hero-bg-2.svg";

type VisualStepperProps = {
  steps: Step[];
};

export type Step = {
  name: string;
  stepNumber: string;
  description: string;
};

export const VisualStepper = (props: VisualStepperProps) => {
  return (
    <div className="relative isolate py-24 sm:py-32">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 transform-gpu overflow-hidden blur-3xl -z-10"
      >
        <img src={heroBg} alt="" className="w-full h-auto object-contain" />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {props.steps.map((item) => (
            <div key={item.name}>
              <div className="flex items-center text-base font-semibold leading-6 text-green-600">
                <svg
                  viewBox="0 0 4 4"
                  aria-hidden="true"
                  className="mr-4 h-1 w-1 flex-none"
                >
                  <circle r={2} cx={2} cy={2} fill="currentColor" />
                </svg>
                {item.stepNumber}
                <div
                  aria-hidden="true"
                  className="absolute -ml-2 h-px w-screen -translate-x-full bg-primary-600/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                />
              </div>
              <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-primary-900">
                {item.name}
              </p>
              <p className="mt-1 text-base leading-7 text-primary-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
