import * as React from "react";

type LegalListProps = {
  items: string[];
  title?: string;
};

export const LegalList = (props: LegalListProps) => {
  return (
    <div className="flex flex-col gap-4">
      {props.title && <h4 className="font-semibold mt-2">{props.title}</h4>}
      <ul className="list-disc list-inside space-y-2 text-sm pb-2">
        {props.items.map((item, index) => (
          <li key={index} className="pl-4">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};
