import * as React from "react";
import { LegalSection, LegalList, LegalDescription } from "../components";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { SectionWrapper, Button } from "~src/components";
import { useNavigate } from "react-router-dom";

export const PrivacyPoliciesView = () => {
  const navigate = useNavigate();
  function autoScrollToTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div className="h-auto bg-primary-500 text-white">
      <SectionWrapper paddingY="small" paddingX="small">
        <div className="flex justify-between items-center">
          <ScaleIcon className="w-16 h-16 text-white" />
          <Button variant="secondary" onClick={() => navigate("/")}>
            Go Back
          </Button>
        </div>
        <h1 className="text-3xl font-bold py-4">
          Afi Technologies - Privacy Policy
        </h1>
        <h3 className="text-lg font-medium pb-6">
          Effective Date: Oct. 19, 2024
        </h3>

        <LegalSection title="1. Introduction">
          <LegalDescription>
            Welcome to Afi Technologies (“we,” “our,” or “us”). We operate the
            website https://www.afitechnologies.io/ (the "Service"). This
            Privacy Policy governs your visit to https://www.afitechnologies.io/
            and explains how we collect, use, safeguard, and disclose
            information resulting from your use of our Service.
          </LegalDescription>
          <LegalDescription>
            By using our Service, you agree to the collection and use of
            information in accordance with this Privacy Policy. Terms used in
            this Privacy Policy have the same meanings as those defined in our
            Terms and Conditions.
          </LegalDescription>
          <LegalDescription>
            Our Terms and Conditions (“Terms”) govern your use of our Service
            and, together with this Privacy Policy, constitute your agreement
            with us ("agreement").
          </LegalDescription>
        </LegalSection>
        <LegalSection title="2. Definitions">
          <LegalList
            items={[
              "SERVICE: Refers to the https://afitechnologies.io/ website, operated by Afi Technologies, which includes subscription-based web development services.",
              "PERSONAL DATA: Refers to information that identifies or can be used to identify an individual directly or indirectly, such as a name, email address, IP address, or other information.",
              "USAGE DATA: Refers to information collected automatically when using the Service, including, but not limited to, IP addresses, browser details, pages visited, and time spent on the Service.",
              "COOKIES: Small files placed on your device to improve your user experience and analyze the use of our Service.",
              "DATA CONTROLLER: A person or entity that determines the purposes and means of processing personal data. For the purposes of this Privacy Policy, Afi Technologies acts as the Data Controller.",
              "DATA PROCESSORS (OR SERVICE PROVIDERS): Refers to entities that process data on behalf of the Data Controller, such as third-party analytics or payment processing providers.",
              "DATA SUBJECT: Refers to any individual whose personal data is being processed.",
              "USER: The individual using the Service, who corresponds to the Data Subject.",
            ]}
          />
        </LegalSection>
        <LegalSection title="3. Information Collection and Use">
          <LegalDescription>
            We collect different types of information for various purposes to
            provide and improve our Service.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="3.1. Types of Data Collected">
          <h3 className="text-lg font-medium mb-2">Personal Data</h3>
          <LegalDescription>
            When you use our Service, we may request personally identifiable
            information, such as:
          </LegalDescription>
          <LegalList
            items={[
              "Email Address: Collected during sign-up or communication to manage your subscription, deliver service updates, and respond to inquiries.",
              "Name and Contact Information: Collected to personalize service delivery, provide customer support, and facilitate billing processes.",
              "Payment Details: Collected and processed through third-party payment processors to manage billing and payments. We do not store your full payment card details.",
            ]}
          />
          <h3 className="text-lg font-medium mb-2">Usage Data</h3>
          <LegalDescription>
            We collect data automatically to understand how our Service is used,
            including:
          </LegalDescription>
          <LegalList
            items={[
              "IP Address and Device Information: Helps to enhance website functionality, manage security, and tailor user experiences.",
              "Browser Type and Version: Used for compatibility and website optimization.",
              "Page Visits and Duration: Helps analyze user interactions and optimize service delivery.",
            ]}
          />
          <h3 className="text-lg font-medium mb-2">Tracking Cookies Data</h3>
          <LegalDescription>
            We use cookies and similar tracking technologies to monitor activity
            and enhance user experience. Cookies are stored on your device and
            may be classified as:
          </LegalDescription>
          <LegalList
            items={[
              "Session Cookies: Temporary cookies used to maintain service functionality during your visit.",
              "Preference Cookies: Used to remember your choices, such as language settings.",
              "Security Cookies: Enhance security measures and prevent unauthorized access.",
              "Analytics Cookies: Gather insights into user behavior to improve service quality.",
            ]}
          />
          <LegalDescription>
            You can manage cookies through your browser settings. Note that
            disabling cookies may limit certain functionalities of the Service.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="3.2. How We Use Your Data">
          <LegalDescription>The data we collect is used for:</LegalDescription>
          <LegalList
            items={[
              "Providing and maintaining the Service: Ensuring the Service operates smoothly and delivers consistent quality.",
              "Personalizing your experience: Tailoring content, communication, and development services based on your preferences.",
              "Improving our Service: Analyzing usage patterns to enhance our offerings, optimize processes, and manage workload effectively.",
              "Managing subscriptions: Facilitating user account creation, handling billing processes, and providing access to subscription-based services.",
              "Ensuring security: Detecting, preventing, and addressing technical issues, fraudulent activity, or security threats.",
              "Communicating with you: Sending important notifications regarding changes to the Service, marketing communications (with consent), and client support updates.",
              "Meeting legal requirements: Complying with legal obligations, responding to lawful requests, and protecting our rights.",
            ]}
          />
        </LegalSection>
        <LegalSection title="4. Retention of Data">
          <LegalDescription>
            We retain your Personal Data only as long as necessary to fulfill
            the purposes described in this Privacy Policy, including:
          </LegalDescription>
          <LegalList
            items={[
              "Subscription Duration: Retained during the active subscription period to facilitate ongoing services.",
              "Legal Obligations: Retained as required by law, such as tax, legal reporting, or other regulatory requirements.",
              "Usage Data: Retained for internal analysis to improve Service performance and user experience. This data is generally retained for a shorter period, except when used for security or functionality enhancements.",
            ]}
          />
          <LegalDescription>
            Personal Data not needed for ongoing services will be deleted or
            anonymized once the retention period expires. Usage Data may be
            retained longer if necessary to ensure the security of the Service
            or to comply with legal obligations.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="5. Transfer of Data">
          <LegalDescription>
            Your information, including Personal Data, may be transferred to—and
            stored on—servers located outside your state, province, country, or
            other governmental jurisdiction where data protection laws may
            differ from those in your jurisdiction. If you choose to provide
            information to us, please note that it may be transferred to and
            processed in the United States.
          </LegalDescription>

          <LegalDescription>
            Afi Technologies will take reasonable steps to ensure that your data
            is treated securely and in accordance with this Privacy Policy, and
            no transfer of your Personal Data will occur to an organization or a
            country unless adequate controls are in place, including the
            security of your data and other personal information.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="6. Disclosure of Data">
          <LegalDescription>
            We may disclose your Personal Data under certain circumstances, as
            described below:
          </LegalDescription>
        </LegalSection>
        <LegalSection title="6.1. Disclosure for Law Enforcement">
          <LegalDescription>
            Under certain conditions, we may be required to disclose your
            Personal Data if mandated by law or in response to valid requests
            from public authorities (e.g., a court or government agency).
          </LegalDescription>
        </LegalSection>
        <LegalSection title="6.2. Business Transactions">
          <LegalDescription>
            If Afi Technologies is involved in a merger, acquisition, or asset
            sale, your Personal Data may be transferred to the new entity,
            subject to the same or equivalent privacy standards outlined in this
            policy. We will provide notice before any such transfer occurs.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="6.3. Other Disclosure Scenarios">
          <LegalDescription>
            We may also disclose your information:
          </LegalDescription>
          <LegalList
            items={[
              "To our subsidiaries and affiliates: For the purpose of supporting our business operations.",
              "To service providers and contractors: Who help us deliver our Service, subject to confidentiality obligations and restrictions on data use.",
              "To fulfill a purpose you provide it for: If you submit data for a specific service or action, we will use and disclose it to fulfill that purpose.",
              "For marketing and promotion (with your consent): We may share non-identifiable data for marketing and promotional activities.",
              "With your explicit consent in other cases: We will seek your consent before sharing your data for any purpose not covered in this policy.",
            ]}
          />
          <LegalDescription>
            We are committed to minimizing data sharing and ensuring that your
            data is protected when disclosure is necessary.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="7. Security of Data">
          <LegalDescription>
            If we become aware of a data breach involving your Personal Data, we
            will notify you promptly and take appropriate steps to mitigate the
            breach in compliance with applicable data protection regulations.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="7. Security of Data">
          <LegalList
            items={[
              "Encryption: We use SSL/TLS encryption to protect data during transmission.",
              "Access Controls: Only authorized personnel have access to Personal Data, and access is restricted based on role and necessity.",
              "Monitoring and Auditing: We conduct regular monitoring and auditing to identify potential security vulnerabilities.",
            ]}
          />
          <LegalDescription>
            We prioritize the security of your data and use commercially
            reasonable measures to protect it from unauthorized access,
            disclosure, alteration, or destruction. However, no method of
            transmission over the Internet or electronic storage is 100% secure,
            and we cannot guarantee absolute security.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="8. Your Data Protection Rights">
          <LegalDescription>
            If you are a resident of the European Economic Area (EEA),
            California, or another jurisdiction with data protection rights, you
            have specific rights regarding your Personal Data:
          </LegalDescription>
        </LegalSection>
        <LegalSection title="8.1. GDPR Rights (for EEA Residents)">
          <LegalDescription>
            If you are a resident of the EEA, you have the following rights
            under the General Data Protection Regulation (GDPR):
          </LegalDescription>
          <LegalList
            items={[
              "Right to Access: You can request copies of your Personal Data.",
              "Right to Rectification: You can ask us to correct inaccurate or incomplete data.",
              "Right to Erasure: You can request the deletion of your Personal Data under certain conditions.",
              "Right to Restrict Processing: You can ask us to limit the processing of your data under specific circumstances.",
              "Right to Data Portability: You can request that we transfer your data to another organization or directly to you.",
              "Right to Object: You can object to the processing of your Personal Data under certain conditions, such as direct marketing.",
            ]}
          />
        </LegalSection>
        <LegalSection title="8.2. CCPA Rights (for California Residents)">
          <LegalDescription>
            If you are a resident of California, you have the following rights
            under the California Consumer Privacy Act (CCPA):
          </LegalDescription>
          <LegalList
            items={[
              "Right to Know: You can request that we disclose the categories and specific pieces of Personal Data we have collected.",
              "Right to Delete: You can request that we delete Personal Data we have collected from you, subject to certain exceptions.",
              "Right to Opt-Out of Sales: Although we do not sell personal information, you have the right to opt out of any future potential sales of your data.",
              "Right to Non-Discrimination: We will not discriminate against you for exercising your rights.",
            ]}
          />
        </LegalSection>
        <LegalSection title="8.3. Exercising Your Data Rights">
          <LegalDescription>
            To exercise any of the rights described above, please contact us at
            afitechfreelance.io@gmail.com. We may request verification of your
            identity before responding to such requests to protect your privacy
            and maintain data security.
          </LegalDescription>
          <LegalDescription>
            Response Time: We will respond to requests within 30 days, as
            required by law, and provide clear instructions for any additional
            steps needed to fulfill your request.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="8.4. Withdrawal of Consent">
          <LegalDescription>
            If we rely on your consent for data processing, you have the right
            to withdraw it at any time. This will not affect the legality of any
            processing conducted prior to withdrawal. However, withdrawing
            consent may impact the delivery of certain services.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="9. Service Providers">
          <LegalDescription>
            We employ third-party companies and individuals to facilitate our
            Service (“Service Providers”), provide services on our behalf,
            perform Service-related functions, or assist in analyzing how our
            Service is used. These third parties have access to your Personal
            Data only to perform specific tasks and are obligated not to
            disclose or use it for any other purpose.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="9.1. Types of Service Providers">
          <h3 className="ml-1 text-base font-medium mb-2">
            a. Payment Processors
          </h3>
          <LegalDescription>
            For paid services, we rely on third-party payment processors to
            manage transactions securely. We do not store your payment card
            details; these are processed directly by our payment partners, which
            adhere to PCI-DSS standards to ensure secure handling of payment
            information.
          </LegalDescription>
          <LegalList
            title="Stripe"
            items={[
              "We use Stripe to process payments securely.",
              "Stripe Privacy Policy: https://stripe.com/us/privacy",
            ]}
          />
          <h3 className="ml-1 text-base font-medium mb-2">
            b. Communication Tools
          </h3>
          <LegalDescription>
            We may use third-party platforms for client communication, project
            management, and support. These platforms help facilitate project
            discussions, feedback, and task tracking in a secure manner.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="9.2. Limitation of Liability for Third-Party Services">
          <LegalDescription>
            While we choose our Service Providers carefully, we are not
            responsible for their data handling practices. We encourage you to
            review their privacy policies to understand how your data is
            managed.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="10. Analytics">
          <LegalDescription>
            We use analytics tools to better understand user behavior, enhance
            user experience, and improve our Service.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="10.1. Purpose of Analytics">
          <LegalDescription>Analytics help us:</LegalDescription>
          <LegalList
            items={[
              "Track performance: Identify bottlenecks or areas for optimization in the user experience.",
              "Understand user interactions: Analyze how users interact with features and content.",
              "Improve services: Use data-driven insights to make informed decisions about Service enhancements.",
            ]}
          />
        </LegalSection>
        <LegalSection title="10.2. Opt-Out Options">
          <LegalDescription>
            If you do not want your data to be collected and analyzed by
            third-party analytics tools, you can:
          </LegalDescription>
          <LegalList
            items={[
              "Adjust browser settings to refuse cookies or notify you when cookies are set.",
              "Use available opt-out tools, such as the Google Analytics Opt-Out Browser Add-On.",
            ]}
          />
          <LegalDescription>
            Please note that opting out of analytics may limit our ability to
            personalize your experience and optimize the Service.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="11. Behavioral Remarketing">
          <LegalDescription>
            Afi Technologies uses remarketing services to advertise on
            third-party websites to you after you have visited our Service. We,
            along with our third-party vendors, use cookies to inform, optimize,
            and serve ads based on your past visits to our Service.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="12. Payments">
          <LegalDescription>
            We provide paid products and services as part of our Service. To
            process payments, we use third-party payment processors that comply
            with PCI-DSS standards to ensure the secure handling of your payment
            information.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="12.1. Payment Process">
          <LegalDescription>
            When you purchase a subscription or service from us:
          </LegalDescription>
          <LegalList
            items={[
              "You will be redirected to a secure payment page.",
              "Your payment information will be processed by our third-party payment processors, such as Stripe.",
              "We do not store full payment card details; only transaction-related information is retained to confirm payment and manage billing records.",
            ]}
          />
        </LegalSection>
        <LegalSection title="12.2. Payment Security">
          <LegalDescription>
            Payment processors adhere to PCI-DSS standards as managed by the PCI
            Security Standards Council, a joint effort by brands like Visa,
            Mastercard, American Express, and Discover. PCI-DSS helps ensure
            secure handling of payment information.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="12.3. Data Collected for Payments">
          <LegalDescription>
            To process your payment, we may collect:
          </LegalDescription>
          <LegalList
            items={[
              "Name and billing address: Required for processing and invoicing.",
              "Partial card details: Used for verification and support, but not for storage.",
              "Transaction history: Maintained for billing records, refunds, or dispute management.",
            ]}
          />
        </LegalSection>
        <LegalSection title="12.4. External Links to Payment Providers">
          <LegalDescription>
            For details on how our payment providers handle data, you are
            encouraged to review their privacy policies:
          </LegalDescription>
          <LegalList items={["Stripe: https://stripe.com/us/privacy"]} />
        </LegalSection>
        <LegalSection title="13. Links to Other Sites">
          <LegalDescription>
            Our Service may contain links to third-party websites that are not
            operated by us. If you click a third-party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy of every site you visit, as we have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party websites or services.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="13.1. Third-Party Content">
          <LegalDescription>
            Occasionally, we may include or offer third-party products or
            services on our website, such as tools, plug-ins, or integrations
            with external platforms. These third-party sites and services have
            separate and independent privacy policies. We encourage users to be
            aware when they leave our site and to read the privacy policies of
            each website that collects personally identifiable information.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="13.2. No Liability for Third-Party Practices">
          <LegalDescription>
            Afi Technologies is not responsible for the practices or content of
            third-party sites, including their data handling, cookies, or
            tracking activities. Your interactions with these third-party sites
            are governed by their own terms and privacy policies.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="14. Children's Privacy">
          <LegalDescription>
            Our Service is not intended for use by children under the age of 13
            ("Children"). We do not knowingly collect personally identifiable
            information from anyone under 13. If you are a parent or guardian
            and you become aware that your child has provided us with Personal
            Data, please contact us. If we discover that we have collected
            Personal Data from children without verification of parental
            consent, we will take steps to remove that information from our
            servers.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="14.1. Measures for Protecting Children's Privacy">
          <LegalDescription>
            If we become aware of any data collected from users under 13 without
            parental consent, we will:
          </LegalDescription>
          <LegalList
            items={[
              "Delete the data promptly: All information related to the child will be permanently removed from our databases.",
              "Notify the parent or guardian: We will inform the parent or guardian of the data collection incident and actions taken to address it.",
            ]}
          />
        </LegalSection>
        <LegalSection title="15. Changes to This Privacy Policy">
          <LegalDescription>
            We may update our Privacy Policy from time to time. Changes to this
            Privacy Policy will be posted on this page, and the "Effective Date"
            at the top of the policy will be updated accordingly. We will notify
            users of significant changes through email or a prominent notice on
            our website before the changes become effective.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="15.1. Your Responsibility to Stay Informed">
          <LegalDescription>
            We encourage users to periodically review this Privacy Policy for
            any changes. Your continued use of the Service after updates
            constitutes your acknowledgment of the updated policy and your
            consent to its terms.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="15.2. Major Policy Changes">
          <LegalDescription>
            In cases of significant changes to how we handle Personal Data, we
            will:
          </LegalDescription>
          <LegalList
            items={[
              "Notify you via email: If you have provided us with your email address, we will inform you directly.",
              "Post a notice on the website: A clear notice will be displayed to indicate a change in the policy.",
              "Give users time to review: If changes require additional consent (e.g., new data collection practices), we will provide an opportunity to review and consent to the changes before continuing to use the Service.",
            ]}
          />
        </LegalSection>
        <LegalSection title="16. Contact Us">
          <LegalDescription>
            If you have any questions, concerns, or comments about this Privacy
            Policy or our data practices, please contact us:
          </LegalDescription>
          <LegalList items={["By email: afitechfreelance.io@gmail.com"]} />
          <LegalDescription>
            We are committed to addressing your privacy concerns promptly and
            will respond to any inquiries within 30 days of receipt.
          </LegalDescription>
        </LegalSection>
        <div className="flex justify-end">
          <Button variant="secondary" onClick={autoScrollToTop}>
            Back to the top
          </Button>
        </div>
      </SectionWrapper>
    </div>
  );
};
