import * as React from "react";
import { useRef, forwardRef, useImperativeHandle } from "react";
import {
  FeatureSection,
  FrequentlyAskQuestionsSection,
  HeroSection,
  HowItWorksSection,
  PricingSection,
  SinglePriceDetailsSection,
} from "../components";
import { faqQuestions } from "../utils/faqQuestions";

export interface LandingViewRef {
  scrollToSection: (sectionName: string) => void;
}

export const LandingView = forwardRef<LandingViewRef>((props, ref) => {
  const heroRef = useRef<HTMLDivElement>(null);
  const featureRef = useRef<HTMLDivElement>(null);
  const howItWorksRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const faqRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (sectionName: string) => {
    const sectionMap: { [key: string]: React.RefObject<HTMLDivElement> } = {
      home: heroRef,
      features: featureRef,
      "how-it-works": howItWorksRef,
      pricing: pricingRef,
      faq: faqRef,
    };

    const targetRef = sectionMap[sectionName];
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useImperativeHandle(ref, () => ({
    scrollToSection,
  }));

  const handleScrollToPricing = () => {
    scrollToSection("pricing");
  };

  return (
    <div className="flex flex-col">
      <HeroSection
        ref={heroRef}
        id="home"
        scrollToSection={handleScrollToPricing}
      />
      <FeatureSection ref={featureRef} id="features" />
      <HowItWorksSection ref={howItWorksRef} id="how-it-works" />
      <PricingSection ref={pricingRef} id="pricing" />
      {/* <SinglePriceDetailsSection /> */}
      <FrequentlyAskQuestionsSection
        subHeader="Commonly Asked Questions"
        items={faqQuestions}
        ref={faqRef}
        id="faq"
      />
    </div>
  );
});
