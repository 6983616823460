import * as React from "react";

type LegalSectionProps = {
  title: string;
  children: React.ReactNode;
};

export const LegalSection = (props: LegalSectionProps) => {
  return (
    <div className="pb-6">
      <h2 className="text-xl font-bold pb-2">{props.title}</h2>
      {props.children}
    </div>
  );
};
