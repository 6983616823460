import * as React from "react";

export const AvailabilityPulse = () => {
  const isAvailable = process.env.REACT_APP_ISAVAILABLE === "1";

  return (
    <div className="flex justify-center items-center gap-4 mt-6">
      <span className="relative flex h-3 w-3">
        {isAvailable && (
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
        )}
        <span
          className={`relative inline-flex rounded-full h-3 w-3 ${
            isAvailable ? "bg-green-500" : "bg-primary-500"
          }`}
        ></span>
      </span>
      <span className="text-lg">
        {isAvailable ? "Available" : "Unavailable"}
      </span>
    </div>
  );
};
