import * as React from "react";
import { forwardRef } from "react";
import {
  ArrowPathIcon,
  SwatchIcon,
  DevicePhoneMobileIcon,
  ServerStackIcon,
  ChatBubbleLeftRightIcon,
  ShieldCheckIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { SectionWrapper } from "~src/components";

type FeatureSectionProps = {
  id?: string;
};

const services = [
  {
    title: "Building Interactive UIs with React, TypeScript, and Tailwind CSS",
    icon: <SwatchIcon className="h-8 w-8 text-green-600" />,
    challenge:
      "Clients often struggle with creating engaging, modern user interfaces that retain users.",
    solution:
      "We build fast, responsive UIs that enhance user engagement and experience, helping you stand out with a polished, interactive front-end that represents your brand effectively.",
  },
  {
    title: "Creating Seamless, Mobile-First Designs",
    icon: <DevicePhoneMobileIcon className="h-8 w-8 text-green-600" />,

    challenge:
      "Ensuring a consistent, user-friendly experience across devices can be challenging for many businesses.",
    solution:
      "With mobile-first, responsive designs, your site automatically adapts to any screen size, increasing reach and accessibility. Clients enjoy smooth navigation, boosting retention and customer satisfaction.",
  },
  {
    title:
      "Building APIs and Supporting Front-End Functionality with Node.js and MongoDB",
    icon: <ServerStackIcon className="h-8 w-8 text-green-600" />,

    challenge:
      "Integrating robust backend functionality that enhances front-end features can be a technical hurdle.",
    solution:
      "We handle essential backend tasks, creating reliable APIs and database management that support your application’s performance, allowing you to offer a seamless user experience without backend issues.",
  },
  {
    title: "Strategic Consulting",
    icon: <ChatBubbleLeftRightIcon className="h-8 w-8 text-green-600" />,
    challenge:
      "Businesses struggle to identify growth opportunities and navigate market changes effectively.",
    solution:
      "Our data-driven approach helps you make informed decisions and develop actionable strategies for sustainable growth.",
  },
  {
    title: "Ensuring Consistent Performance and Security",
    icon: <ShieldCheckIcon className="h-8 w-8 text-green-600" />,

    challenge:
      "Keeping a website up-to-date, secure, and bug-free requires ongoing attention that many businesses lack resources for.",
    solution:
      "We provide regular maintenance, optimizations, and updates, ensuring that your site remains secure and performs optimally. This proactive support reduces downtime and keeps users satisfied.",
  },
  {
    title: "Enhancing User Experience and Brand Alignment",
    icon: <HandThumbUpIcon className="h-8 w-8 text-green-600" />,

    challenge:
      "Crafting a cohesive, user-friendly experience that aligns with brand identity can be complex.",
    solution:
      "We collaborate on UI/UX improvements, creating designs that are not only visually pleasing but also intuitive for users, increasing user satisfaction and aligning with your brand values.",
  },
];

export const FeatureSection = forwardRef<HTMLDivElement, FeatureSectionProps>(
  (props, ref) => {
    return (
      <SectionWrapper ref={ref} id={props.id}>
        <div className="mx-auto lg:max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-green-600">
            Our services are unbeatable
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
            Our services solve your biggest challenges
          </p>
          <p className="mt-6 text-lg leading-8 text-primary-600">
            Ever had an app idea but didn't know where to start? Worried about
            needing a large capital for development work? We've got you covered.
          </p>
        </div>
        <div className="relative mt-8 grid md:grid-cols-1 lg:grid-cols-2 gap-8">
          {services.map((service, idx) => (
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="flex items-left mb-4">
                <div className="mr-4">{service.icon}</div>
                <h3 className="text-xl font-bold text-gray-800">
                  {service.title}
                </h3>
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-semibold text-gray-500 mb-2">
                    The Challenge
                  </h4>
                  <p className="text-gray-600">{service.challenge}</p>
                </div>

                <div>
                  <h4 className="text-sm font-semibold text-primary-800 mb-2">
                    Our Solution
                  </h4>
                  <p className="text-gray-600">{service.solution}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    );
  }
);

FeatureSection.displayName = "FeatureSection";
