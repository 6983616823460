import * as React from "react";
import { footerNavItems } from "~src/utils/footerNavItems";

export const Footer = () => {
  return (
    <footer className="bg-primary-500 mt-auto" role="navigation">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-12 sm:py-18 lg:px-8">
        <div className="flex justify-center items-center gap-12">
          {footerNavItems.links.map((item) => (
            <div key={item.name}>
              <ul className="mt-4 space-y-2">
                <li>
                  <a
                    href={item.href}
                    className="text-sm leading-5 text-primary-300 hover:text-primary-100"
                  >
                    {item.name}
                  </a>
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div className="mt-10 flex justify-center space-x-10">
          {footerNavItems.social.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-primary-200 hover:text-primary-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon aria-hidden="true" className="h-6 w-6" />
            </a>
          ))}
        </div>
        <p className="mt-10 text-center text-xs leading-5 text-primary-300">
          &copy; 2024 AFI Technologies, Inc. All rights reserved.
        </p>
      </div>
    </footer>
  );
};
