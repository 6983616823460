import * as React from "react";
import {
  SubscriptionPlan,
  LegalSection,
  LegalList,
  LegalDescription,
  RefundPolicyPlan,
} from "../components";
import { SectionWrapper, Button } from "~src/components";
import { ScaleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export const TermsAndConditionsView = () => {
  const navigate = useNavigate();
  function autoScrollToTop() {
    window.scrollTo(0, 0);
  }
  return (
    <div className="h-auto bg-primary-500 text-white">
      <SectionWrapper paddingY="small" paddingX="small">
        <div className="flex justify-between items-center">
          <ScaleIcon className="w-16 h-16 text-white" />
          <Button variant="secondary" onClick={() => navigate("/")}>
            Go Back
          </Button>
        </div>
        <h1 className="text-3xl font-bold py-4">Terms and Conditions</h1>
        <h3 className="text-lg font-medium pb-6">
          Last Updated: Oct. 19, 2024
        </h3>
        <LegalSection title="Introduction">
          <LegalDescription>
            These Terms & Conditions ("Terms") form a legally binding agreement
            between Afi Technologies ("Company," "we," "us") and the client
            ("Client," "you"), governing your access and use of our services and
            website, including any media, channels, or mobile applications
            related to it (collectively, the "Website"). By using our Website or
            subscribing to our services, you agree to comply with these Terms.
            If you disagree, you must discontinue use immediately.
          </LegalDescription>
        </LegalSection>

        <LegalSection title="1. Intellectual Property Rights">
          <LegalDescription>
            All content on the Website, including source code, designs,
            databases, and graphics ("Content"), is the exclusive property of
            the Company and is protected by intellectual property laws. You may
            not reproduce, distribute, or exploit any part of the Content
            without written consent.
          </LegalDescription>
          <LegalList
            items={[
              "Client Deliverables: The completed deliverables for development tasks will become the property of the Client upon full payment. However, any underlying frameworks, pre-existing code libraries, or third-party integrations remain the property of the Company or their respective owners.",
              "Work-for-Hire: The final deliverables will be considered 'work-for-hire,' transferring ownership rights to the Client once the project is delivered and paid for in full.",
            ]}
          />
        </LegalSection>

        <LegalSection title="2. Subscription Plans">
          <LegalDescription>
            We offer three subscription plans with different features, pricing,
            and turnaround times:
          </LegalDescription>
          <SubscriptionPlan
            title="Essential Development Plan"
            price="$2,995"
            features={[
              "Unlimited Web Development Requests",
              "One Active Request at a Time",
              "Standard Turnaround Time (3-5 Business Days)",
              "Asynchronous Communication",
              "Frontend Development Services",
              "Basic Backend Development",
              "Repsonsive Design Implementation",
              "Bug Fixes and Minor Updates",
              "No Long-Term Commitment",
            ]}
            suitableFor="Startups and small businesses needing consistent support."
          />
          <SubscriptionPlan
            title="Professional Development Plan"
            price="$4,995"
            features={[
              "Two Active Requests at a Time",
              "Faster Turnaround Time (2-3 Business Days)",
              "Priority Support",
              "Monthly Strategy Sessions",
              "Advanced Backend Development",
              "Performance Optimization",
              "Access to Design Collaboration",
              "Dedicated Communication Channel",
            ]}
            suitableFor="Businesses with ongoing development needs and tighter deadlines."
          />
          <SubscriptionPlan
            title="Enterprise Development Plan"
            price="$7,995"
            features={[
              "Multiple Active Requests",
              "Fastest Turnaround Time (1-2 Business Days)",
              "Weekly Strategy Sessions",
              "Customized Services",
              "Extended Backend Development",
              "Comprenhensive Testing and QA",
              "DevOps Assistance",
              "Priority Feature Implementation",
              "Code Review and Consulation",
              "Extended Documentation",
              "Non-Disclosure Agreement",
            ]}
            suitableFor="Large companies and agencies with complex development requirements."
          />
        </LegalSection>
        <LegalSection title="3. User Responsibilities">
          <LegalDescription>
            By using our services, you represent and warrant that:
          </LegalDescription>
          <LegalList
            items={[
              "You have the legal capacity to enter into these Terms.",
              "You will provide accurate information, materials, and access credentials needed for project completion.",
              "You will use our services only for lawful purposes and in compliance with applicable laws.",
              "You will maintain active communication for any clarifications or additional information needed for task fulfillment.",
            ]}
          />
        </LegalSection>
        <LegalSection title="4. Prohibited Activities">
          <LegalDescription>Clients must not:</LegalDescription>
          <LegalList
            items={[
              "Use the Website or services for unauthorized, unlawful, or fraudulent activities.",
              "Attempt to hack, circumvent security measures, modify, decompile, or reverse-engineer any part of the Website or software.",
              "Access, scrape, or extract data from the Website for unauthorized use or commercial purposes.",
              "Submit malicious content, such as viruses, malware, or code that may harm systems or breach security measures.",
              "Reproduce, copy, or distribute any part of the Website or underlying software without permission.",
            ]}
          />
        </LegalSection>
        <LegalSection title="5. Third-Party License">
          <LegalDescription>
            If third-party resources (e.g., fonts, libraries, APIs) are required
            for a project, the following applies:
          </LegalDescription>
          <LegalList
            items={[
              "Client Responsibility: The Client must purchase the necessary licenses for any third-party tools or assets used within the project deliverables. This includes, but is not limited to, commercial fonts, libraries, and APIs.",
              "Notification: The Company will notify the Client if any third-party licenses are required, providing details on the license type, pricing, and vendor information.",
              "Compliance: It is the Client's responsibility to ensure legal use of third-party resources. Failure to obtain necessary licenses may result in legal consequences, for which the Client assumes full responsibility.",
            ]}
          />
        </LegalSection>
        <LegalSection title="6. Client Feedback">
          <LegalDescription>
            Any feedback, suggestions, ideas, or other submissions provided by
            you regarding the Company’s services become the Company’s exclusive
            property, unless otherwise stated in writing. By submitting
            feedback, you agree that:
          </LegalDescription>
          <LegalList
            items={[
              "The Company has full rights to use, implement, or modify the feedback to improve services without any obligation or compensation to you.",
              "The Company is not required to keep feedback confidential or proprietary.",
              "Feedback provided will not infringe on any third-party rights.",
            ]}
          />
          <LegalDescription>
            The Company will retain rights to feedback-related improvements,
            excluding any deliverables already assigned to the Client under
            work-for-hire agreements.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="7. Service Availability">
          <LegalDescription>
            The Company strives to provide uninterrupted access to the Website
            and services, but does not guarantee 100% uptime. Interruptions may
            occur due to:
          </LegalDescription>
          <LegalList
            items={[
              "Technical Issues: Such as server failures, software errors, or other unforeseen challenges.",
              "Maintenance: Scheduled updates or improvements to the Website or services.",
              "Client Delays: Turnaround times are based on prompt Client responses and necessary asset submissions. Delays in communication or incomplete information may extend task completion times.",
            ]}
          />
          <LegalDescription>
            Turnaround times are estimates and can vary based on task
            complexity, project backlog, or unforeseen circumstances.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="8. Refund Policy">
          <RefundPolicyPlan
            refundWindowItems={[
              "Clients can request a refund within the first 48 hours after subscribing.",
              "Refund requests must be made in writing via email to afitechfreelance within this period.",
              "Any refund issued will result in the immediate termination of services, and any work completed during this window remains the Company's property and cannot be used by the Client.",
            ]}
            nonRefundableItems={[
              "Any onboarding or setup fees are non-refundable, regardless of the refund request.",
            ]}
            refundDenialItems={[
              "No refunds will be considered after the initial 48-hour period, even if the subscription has not been fully utilized.",
              "Clients can choose to cancel their subscription at any time, but no prorated refunds or credits will be provided for unused days.",
              "The subscription period is billed on a 31-day cycle. If the Client opts to pause or cancel the subscription after the 48-hour refund window, they will retain access to services for the remaining days of the current billing cycle. During this time, the Client may continue to submit and receive development work as per the terms of their subscription.",
            ]}
          />
        </LegalSection>
        <LegalSection title="9. Modifications to Terms">
          <LegalDescription>
            The Company reserves the right to modify these Terms at any time.
            Any modifications will:
          </LegalDescription>
          <LegalList
            items={[
              "Be communicated via email or prominently posted on the Website.",
              "Take effect immediately upon posting, unless specified otherwise.",
              "Provide Clients with a 10-day grace period to review changes and, if desired, terminate the agreement.",
            ]}
          />
          <LegalDescription>
            Continued use of the Website or services after the modification
            period indicates acceptance of the new Terms. If you do not agree
            with the modifications, you must discontinue use and notify the
            Company within the 10-day period.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="10. Governing Law">
          <LegalDescription>
            These Terms are governed by and construed in accordance with the
            laws of Utah of the United States. Any disputes related to these
            Terms or the services provided will:
          </LegalDescription>
          <LegalList
            items={[
              "Be subject to jurisdiction and venue in the state of Utah of the United States.",
              "Be resolved through mediation or arbitration before pursuing formal litigation, if mutually agreed upon by both parties.",
            ]}
          />
          <LegalDescription>
            The Company and the Client agree to comply with local, state,
            national, and international laws that apply to these Terms.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="11. Indemnification">
          <LegalDescription>
            You agree to indemnify, defend, and hold harmless the Company, its
            directors, officers, employees, contractors, and agents from any
            claims, damages, liabilities, costs, and expenses (including legal
            fees) arising from:
          </LegalDescription>
          <LegalList
            items={[
              "Misuse of the Website or services.",
              "Violation of these Terms by the Client.",
              "Infringement of third-party rights, including intellectual property rights, data breaches, or unauthorized use of deliverables.",
              "Any breach of applicable laws in connection with your use of our services.",
            ]}
          />
          <LegalDescription>
            The Company reserves the right to assume exclusive defense and
            control of any matter subject to indemnification at the Client's
            expense. The Client agrees to cooperate fully with the Company’s
            defense of such claims.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="12. Electronic Communications">
          <LegalDescription>
            By using our services, you consent to receive electronic
            communications from the Company, which may include:
          </LegalDescription>
          <LegalList
            items={[
              "Agreements, notices, disclosures, and other communications related to services provided.",
              "Use of electronic signatures, records, and communications for transactions.",
              "Delivery of notices and records of transactions via email or through the Website.",
            ]}
          />
          <LegalDescription>
            All electronic communications are deemed to meet legal requirements
            for written communication. You agree that digital signatures and
            electronic records hold the same validity as physical signatures and
            records.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="13. Privacy and Confidentiality">
          <LegalDescription>
            We are committed to safeguarding your information. The handling of
            data is restricted to one developer, which ensures high
            confidentiality and reduced risk of data breaches.
          </LegalDescription>
          <LegalList
            items={[
              "Data Handling: All sensitive information will be encrypted and stored securely. Clients are advised to avoid sharing unnecessary personal data during the project.",
              "Confidentiality: The Company will not disclose confidential information to third parties, except as required by law.",
              "Sensitive Data: Clients must notify the Company in advance if a project involves sensitive data. The Company will take extra measures to ensure secure handling, but the Client remains responsible for informing the Company of any specific confidentiality requirements.",
            ]}
          />
          <LegalDescription>
            For more details, refer to the Privacy Policy on the Website.
          </LegalDescription>
        </LegalSection>
        <LegalSection title="14. Showcasing Work">
          <LegalDescription>
            The Company reserves the right to publicly showcase completed
            projects unless explicitly restricted by the Client through a signed
            Non-Disclosure Agreement (NDA).
          </LegalDescription>
          <LegalList
            items={[
              "Examples of Showcasing: Social media posts, portfolio pages, or website case studies.",
              "Exceptions: Proprietary backend processes or sensitive data integrations will not be disclosed publicly.",
              "NDA Option: Clients can request an NDA to prevent public showcasing of deliverables. Any NDA must be mutually signed before project initiation to take effect.",
            ]}
          />
        </LegalSection>
        <LegalSection title="15. Miscellaneous">
          <LegalDescription>
            This section covers additional legal elements of these Terms:
          </LegalDescription>
          <LegalList
            items={[
              "Force Majeure: The Company is not liable for delays or failures in performance caused by events beyond reasonable control, such as natural disasters, technical failures, or acts of government.",
              "Non-Solicitation: During the term of service and for 12 months afterward, the Client agrees not to directly hire or solicit the Company’s contractor(s) or team members for personal or business projects.",
              "Entire Agreement: These Terms, along with the Privacy Policy and any other policies posted on the Website, constitute the entire agreement between the Company and the Client.",
              "Severability: If any provision of these Terms is found to be unlawful, void, or unenforceable, it shall be severed from the Terms without affecting the enforceability of the remaining provisions.",
              "Waiver: Failure of the Company to enforce any right or provision shall not constitute a waiver of that right or provision.",
            ]}
          />
        </LegalSection>
        <LegalSection title="16. Contact Information">
          <LegalDescription>
            For any questions, feedback, or complaints regarding the Terms or
            services, please contact the Company at
            afitechfreelance.io@gmail.com.
          </LegalDescription>
        </LegalSection>
        <div className="flex justify-end">
          <Button variant="secondary" onClick={autoScrollToTop}>
            Back to the top
          </Button>
        </div>
      </SectionWrapper>
    </div>
  );
};
