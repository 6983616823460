import { Step } from "../components/VisualStepper";
export const howItWorksStepsList: Step[] = [
  {
    stepNumber: "Step 1",
    name: "Choose your plan",
    description:
      "Browse and select the subscription plan that best fits your needs.",
  },
  {
    stepNumber: "Step 2",
    name: "Onboarding and Setup",
    description:
      "After signing up, you'll be given instructions via email on how to submit your requests and send assets.",
  },
  {
    stepNumber: "Step 3",
    name: "Submit Your Requests",
    description:
      "Start immediately. You can submit unlimited requests BUT depending on you plan, we can only work on a certain number of requests at a time.",
  },
  {
    stepNumber: "Step 4",
    name: "Development in Progress",
    description:
      "We'll start working on your requests and keep you updated on the progress. Turnaround time is 1-7+ business days depending on your subscription plan.",
  },
  {
    stepNumber: "Step 5",
    name: "Review and Revise",
    description:
      "Once we've completed your requests, you'll have the opportunity to review and request revisions.",
  },
  {
    stepNumber: "Step 6",
    name: "Rinse and Repeat",
    description:
      "After you've reviewed and approved the work, you can submit more requests. We'll place the task card in the 'Done' column and we'll continue to work on your requests as long as you're subscribed.",
  },
];

