export const faqQuestions = [
  {
    title: "What will I get in the first week of my subscription with you?",
    description: [
      "In the first week, you'll recieve an onboarding email with instructions on how to submit requests and send assets. You'll also get a basic app with basic components like a header, footer, and a other basic components. You'll be able to see application from a development url.",
      "For mobile apps, you'll be able to see the app on your phone using the Expo Go app.",
    ],
  },
  {
    title: "Can I really submit unlimited requests?",
    description: [
      "Yes, you can submit as many requests as you like. However, depending on your plan, we can only work on a certain number of requests at a time.",
      "Also, depending on the complexity of the requests, we'll break them down into smaller sub-requests to ensure we deliver the overall complex request with the best quality.",
    ],
  },
  {
    title: "What types of web development tasks can I request?",
    description: [
      "You can request frontend and backend tasks like React/Node(Express) development, design-to-code conversions, feature additions, bug fixes, and performance optimizations. We also offer mobile app development, we use React Native which is a cross platform framework that allows us to create apps for both iOS and Andriod phones with one codebase.",
    ],
  },
  {
    title: "What is the typical turnaround time for a request?",
    description: [
      "Turnaround times range from 1-7+ business days per task, depending on your subscription plan and the complexity of the request.",
    ],
  },
  {
    title: "Why wouldn't I just hire a full-time developer?",
    description: [
      "Hiring a full-time senior fullstack developer be expensive. You'd have to pay a salary that could easily be over $120,000/year. You might not have enough work to keep them busy full-time. You'd also have to pay for benefits, vacation time, sick days, and other expenses.",
      "With the monthly plan, you can pause your subscription as often as you need to ensure you're only paying for the work you need.",
    ],
  },
  {
    title: "How does the pause feature work?",
    description: [
      "You might not have a lot of development requests for whatever stage your project might be in and that's okay! You can pause your subscription and resume it when you're ready to submit more requests. All work will effectively be paused as well.",
      "The billing cycle is 31 days. Let's say you use 11 days of service and then decide to pause your subscription. This means your billing cycle will be paused and you'll have 20 more days of service to use anytime in the future.",
    ],
  },
  {
    title: "What if I don't like the work being done?",
    description: [
      "Don't worry! We'll continue to revise the work until you're 100% satisfied.",
    ],
  },
  {
    title:
      "What if I need something done urgently or have a high-priority task?",
    description: [
      "We get it! Sometimes you need things done yesterday. If you have a high-priority request, you'll want to put the that request at the top of the list. We'll do our best to get it done as soon as possible.",
    ],
  },
  {
    title: "What types of apps can I request?",
    description: [
      "If you have an existing app that needs updates or new features, we can help. If you have an idea for a new app, we can help with that too. We can build apps for both iOS and Android using React Native.",
    ],
  },
  {
    title: "Can I get a refund if I'm not satisfied?",
    description: [
      "Due to the high quality nature of the work, there will be no refunds issued.",
    ],
  },
];
