import * as React from "react";

type ButtonVariant = "primary" | "secondary" | "accent" | "success" | "warning";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "primary",
  className = "",
  size = "medium",
  ...props
}) => {
  const buttonSizeClasses = {
    small: "px-2 py-1 text-sm",
    medium: "px-4 py-2 text-base",
    large: "px-6 py-3 text-lg",
    xlarge: "px-8 py-4 text-xl",
    xxlarge: "px-16 py-8 text-3xl",
  };

  const baseClasses = `flex items-center cursor-pointer justify-center rounded-md font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-300 ${buttonSizeClasses[size]}`;

  const variantClasses = {
    primary:
      "bg-primary-500 text-white hover:bg-primary-400 dark:bg-primary-600 dark:hover:bg-primary-700 disabled:bg-primary-400 disabled:cursor-not-allowed",
    secondary:
      "bg-blue-200 text-blue-800 hover:bg-blue-300 dark:bg-blue-700 dark:text-blue-200 dark:hover:bg-blue-600 disabled:bg-blue-400 disabled:cursor-not-allowed",
    accent:
      "bg-red-500 text-white hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700 disabled:bg-red-400 disabled:cursor-not-allowed",
    success:
      "bg-green-500 text-white hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700 disabled:bg-green-400 disabled:cursor-not-allowed",
    warning:
      "bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700 disabled:bg-yellow-400 disabled:cursor-not-allowed",
  };

  const combinedClasses = `${baseClasses} ${variantClasses[variant]} ${className}`;

  return (
    <button className={combinedClasses} {...props}>
      {children}
    </button>
  );
};
