import * as React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  {
    name: "SEO Optimization",
    description:
      "Implements key on-page SEO practices to increase search engine visibility and attract organic traffic.",
  },
  {
    name: "Analytics Integration",
    description:
      "Sets up tools like Google Analytics to track visitor interactions, helping measure and optimize page performance.",
  },
  {
    name: "Newsletter Signup Integration",
    description:
      "Adds a sign-up form linked to an email marketing platform, enabling lead capture for future campaigns.",
  },
  {
    name: "Social Media Integration",
    description:
      "Provides clickable social media icons that link directly to the client’s platforms, enhancing engagement and reach.",
  },
];

export const SinglePriceDetailsSection = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl sm:text-center">
          <h2 className="text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-6xl">
            Need a simple landing page to get started?
          </h2>
          <p className="mx-auto mt-6 max-w-2xl text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Our Response Landing Page is perfect for showcasing your product or
            service and converting visitors into leads.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-semibold tracking-tight text-gray-900">
              Responsive Landing Page
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              This offer comes with 5 sections of your choosing and we won't
              stop until you're satisfied with the design, layout, and
              functionality. You'll also get 2 months of free maintenance and
              support.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-green-600">
                Some Features:
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature, idx) => (
                <li
                  key={`${feature.name}-${idx}`}
                  className="flex flex-col gap-x-3"
                >
                  <span className="text-base font-medium">{feature.name}</span>
                  <span className="text-sm">{feature.description}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Flat Rate
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-2xl font-semibold tracking-tight text-gray-900">
                    $1200
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    /USD
                  </span>
                </p>
                <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
