import * as React from "react";
import { forwardRef } from "react";
import { SectionWrapper } from "~src/components";
import { VisualStepper } from "../VisualStepper";
import { howItWorksStepsList } from "../../utils/defaultStepLists";

type HowItWorksSectionProps = {
  id?: string;
};

export const HowItWorksSection = forwardRef<
  HTMLDivElement,
  HowItWorksSectionProps
>((props, ref) => {
  return (
    <SectionWrapper ref={ref} id={props.id}>
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-green-600">
          How does it work?
        </h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-primary-900 sm:text-4xl">
          Transforming Your Ideas into Reality—Seamlessly
        </p>
        <p className="mt-6 text-lg leading-8 text-primary-600">
          At our web agency, we offer a straightforward and efficient service
          model: unlimited web development requests for a flat monthly fee. No
          need to juggle multiple freelancers or complex contracts—just submit
          your requests, and we’ll handle the rest. With a clear pricing
          structure and dedicated support, you can focus on growing your
          business while we take care of your web development needs.
        </p>
      </div>
      <VisualStepper steps={howItWorksStepsList} />
    </SectionWrapper>
  );
});

HowItWorksSection.displayName = "HowItWorksSection";
