import * as React from "react";
import { forwardRef } from "react";
import { FAQItem } from "../FAQItem";
import { SectionWrapper } from "~src/components";

type FrequentlyAskQuestionsSectionProps = {
  items: Item[];
  subHeader: string;
  description?: string;
  id?: string;
};

type Item = {
  title: string;
  description: string[];
};

export const FrequentlyAskQuestionsSection = forwardRef<
  HTMLDivElement,
  FrequentlyAskQuestionsSectionProps
>((props, ref) => {
  return (
    <SectionWrapper ref={ref} id={props.id}>
      <div className="mx-auto max-w-4xl lg:text-center pb-8">
        <h2 className="text-base font-semibold leading-7 text-green-600">
          Here's what you need to know
        </h2>
        <p className="mt-4 text-3xl font-bold tracking-tight text-primary-500 sm:text-5xl">
          {props.subHeader}
        </p>
        {props.description && (
          <p className="mt-6 text-lg leading-8 text-primary-600">
            {props.description}
          </p>
        )}
      </div>
      <div className="space-y-4">
        {props.items.map((item, index) => (
          <FAQItem key={index} title={item.title}>
            <div className="flex flex-col space-y-6">
              {item.description.map((desc, index) => (
                <p key={index} className="text-base leading-7 text-primary-600">
                  {desc}
                </p>
              ))}
            </div>
          </FAQItem>
        ))}
      </div>
    </SectionWrapper>
  );
});

FrequentlyAskQuestionsSection.displayName = "FrequentlyAskQuestionsSection";
