import * as React from "react";
import { useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LandingView,
  TermsAndConditionsView,
  PrivacyPoliciesView,
} from "~src/features";
import { Navbar, Footer } from "~src/components";

function NonAuthenticatedRoutes() {
  const location = useLocation();
  const navigate = useNavigate();
  const landingViewRef = useRef<React.ElementRef<typeof LandingView>>(null);

  const [navigation, setNavigation] = React.useState([
    { name: "Home", href: "#home", current: true },
    { name: "Features", href: "#features", current: false },
    { name: "How It Works", href: "#how-it-works", current: false },
    { name: "Pricing", href: "#pricing", current: false },
    { name: "FAQ", href: "#faq", current: false },
  ]);

  const updatedNavItems = useMemo(() => {
    return navigation.map((item) => ({
      ...item,
      current: item.href === location.hash,
    }));
  }, [location.hash, navigation]);

  useEffect(() => {
    setNavigation(updatedNavItems);
  }, []);

  useEffect(() => {
    if (location.hash) {
      landingViewRef.current?.scrollToSection(location.hash.slice(1));
    }
  }, [location.hash]);

  const handleNavClick = (href: string) => {
    navigate(href);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar
        navigation={navigation}
        onNavClick={handleNavClick}
        variant="secondary"
      />
      <main>
        <LandingView ref={landingViewRef} />
      </main>
      <Footer />
    </div>
  );
}

export const mainRoutes = [
  {
    path: "/",
    element: <NonAuthenticatedRoutes />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditionsView />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPoliciesView />,
  },
];
