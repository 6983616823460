import * as React from "react";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

type NavItem = {
  name: string;
  href: string;
  current: boolean;
};

type NavbarProps = {
  navigation: NavItem[];
  logoSrc?: string;
  variant?: "primary" | "secondary" | "success" | "danger";
  onNavClick: (href: string) => void; // Add this line
};

const variantClasses = {
  primary: {
    base: "bg-blue-600",
    text: "text-white",
    hover: "hover:bg-blue-700",
    active: "bg-blue-800",
  },
  secondary: {
    base: "bg-primary-500",
    text: "text-white",
    hover: "hover:bg-primary-700",
    active: "bg-primary-600",
  },
  success: {
    base: "bg-green-600",
    text: "text-white",
    hover: "hover:bg-green-700",
    active: "bg-green-800",
  },
  danger: {
    base: "bg-red-600",
    text: "text-white",
    hover: "hover:bg-red-700",
    active: "bg-red-800",
  },
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Navbar: React.FC<NavbarProps> = ({
  navigation,
  logoSrc,
  variant = "secondary",
  onNavClick, // Add this line
}) => {
  const variantStyle = variantClasses[variant];

  const handleNavItemClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    href: string
  ) => {
    e.preventDefault();
    onNavClick(href);
  };

  return (
    <Disclosure
      as="nav"
      className={`${variantStyle.base} w-full fixed z-10`}
      role="navigation"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <DisclosureButton
                  className={`group relative inline-flex items-center justify-center rounded-md p-2 ${variantStyle.text} ${variantStyle.hover} focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white`}
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  {logoSrc ? (
                    <img
                      className="h-8 w-auto"
                      src={logoSrc}
                      alt="Your Company"
                    />
                  ) : (
                    <span className="text-white text-xl font-semibold tracking-wider">
                      AFI TECHNOLOGIES
                    </span>
                  )}
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        onClick={(e) => handleNavItemClick(e, item.href)}
                        className={classNames(
                          item.current
                            ? variantStyle.active
                            : variantStyle.hover,
                          variantStyle.text,
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  onClick={(e) => handleNavItemClick(e, item.href)}
                  className={classNames(
                    item.current ? variantStyle.active : variantStyle.hover,
                    variantStyle.text,
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
};
